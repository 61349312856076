@media only screen and (max-width : 767px) {

    /* Default CSS */
    body {
        font-size: 14px;
    }
    .ptb-100 {
        padding-top: 50px;
        padding-bottom: 50px;
    }
    .pt-100 {
        padding-top: 50px;
    }
    .ptb-70 {
        padding-top: 50px;
        padding-bottom: 20px;
    }
    .pb-100 {
        padding-bottom: 50px;
    }
    .pb-70 {
        padding-bottom: 20px;
    }
    .pt-45 {
        padding-top: 30px;
    }
    .default-btn {
        margin: 5px;
        padding: 8px 45px 8px 15px;

        i {
            width: 30px;
            height: 30px;
            line-height: 30px;
            font-size: 16px;
        }
    }
    .default-btn-two {
        padding: 8px 20px;
    }
    .section-title {
        span {
            font-size: 12px;
        }
        h2 {
            font-size: 24px;
        }
        p {
            padding-top: 10px;
        }
    }
    /* Default CSS End */

    /* Navbar CSS */
    .mobile-nav {
        .logo {
            img {
                max-width: 150px;
                height: auto;
                margin-top: -5px;
            }
        }
    }
    .d-in-line {
        display: none;
    }
    .side-nav-responsive {
        display: block;
    }
    .search-overlay {
        .search-form {
            form {
                .input-search {
                    height: 50px;
                    padding: 0 0 0 15px;
                }
            }
        }
    }
    .search-overlay {
        .search-form {
            max-width: 100%;
            width: 100%;

            form {
                .input-search {
                    height: 50px;
                    padding: 0 0 0 15px;
                }
                button {
                    position: absolute;
                    right: 5px;
                    top: 5px;
                    width: 40px;
                    height: 40px;
                    font-size: 18px;
                    line-height: 35px;
                }
            }
        }
    }
    .side-nav-responsive {
        .side-nav-inner {
            .side-nav {
                text-align: center;

                .side-item {
                    padding-left: unset;
                    margin: 0 10px;
                    text-align: center;
                }
            }
        }
    }
    /* Navbar CSS End */

    /* Main Banner CSS */
    .banner-content {
        padding-top: 110px;
        padding-bottom: 20px;

        .title {
            height: 32px;
            line-height: 30px;
            padding-right: 10px;

            i {
                width: 30px;
                height: 30px;
                line-height: 30px;
                font-size: 16px;
            }
            span {
                top: -1px;
            }
        }
        h1 {
            margin-top: 15px;
            margin-bottom: 10px;
            font-size: 40px;
        }
        p {
            font-size: 16px;
        }
        .banner-btn {
            margin-top: 20px;

            .get-btn {
                margin: 5px;
                padding: 8px 45px 8px 15px;

                i {
                    width: 30px;
                    height: 30px;
                    line-height: 30px;
                    font-size: 16px;
                }
            }
            .call-btn {
                margin: 5px;
                padding: 8px 15px 8px 45px;

                i {
                    width: 30px;
                    height: 30px;
                    line-height: 30px;
                    font-size: 16px;
                }
            }
        } 
    }
    .banner-shape {
        display: none;
    }
    .banner-shape-right {
        display: none;
    }
    .banner-img {
        position: inherit;
        top: 0;
        margin-bottom: 50px;

        img {
            max-width: 100%;
            height: auto;
        }
    }
    .banner-area-two {
        background-image: none;
        background-color: #2966be;
        height: auto;

        &::before {
            display: none;
        }
    }
    .banner-content-two {
        padding-top: 140px;
        padding-bottom: 30px;

        h1 {
            font-size: 40px;
        }
        .banner-content-btn {
            margin-top: 30px;

            .learn-btn {
                padding: 8px 45px 8px 15px;

                i {
                    width: 30px;
                    height: 30px;
                    line-height: 30px;
                    font-size: 16px;
                }
            }
            .play-on-btn {
                margin-left: 15px;
                top: 0px;
            }
        }
    }
    .banner-img-2 {
        margin-top: 0;
        padding-bottom: 70px;
    }
    .banner-bottom-shape {
        display: none;
    }
    .banner-content-three {
        padding-top: 140px;
        padding-bottom: 30px;

        h1 {
            font-size: 34px;
            margin-bottom: 20px;
        }
        p {
            font-size: 16px;
        }
        .banner-three-btn {
            margin-top: 20px;

            .explore-btn {
                padding: 8px 16px;
            }
            .play-two-btn {
                margin-left: 15px;
                top: -5px;
            }
        }
    }
    .banner-img-3 {
        position: inherit;
        bottom: 0;
        right: 0;
        left: 0;
        text-align: center;
        
        img {
            max-width: 100%;
            height: auto;
        }
    }
    .banner-three-shape {
        display: none;
    }
    /* Main Banner CSS End */

    /* Inner Banner CSS */
    .inner-banner {
        background-image: none;
        background-color: #0149b1;
        height: auto;

        &::before {
           display: none;
        }
        .inner-title {
            padding-top: 120px;
            padding-bottom: 70px;

            h3 {
                font-size: 30px;
                margin-bottom: 0;
            }
            ul {
                li {
                    font-size: 16px;
                    margin-right: 0;
                }
            }
            .inner-list {
                li {
                    font-size: 16px;
                    margin-top: 10px;
                    margin-right: 5px;

                    i {
                        font-size: 20px;
                        top: 4px;
                    }
                }
            }
        }
        .inner-banner-shape {
            display: none;
        }
    }
    /* Inner Banner CSS End */

    .schedule-shape {
        display: none;
    }

    /* Brand Area CSS */
    .brand-area {
        padding-bottom: 30px;

        .owl-nav {
            right: 0;
            left: 0;
            top: auto;
            bottom: -40px;
            margin-bottom: 0;
        }
    }
    .brand-max {
        width: 100%;
        padding-left: 15px;
        padding-right: 15px;
    }
    .brand-slider {
        max-width: 100%;
        padding-bottom: 0;
    }
    /* Brand Area CSS End */

    /* About Area CSS */
    .about-shape {
        display: none;
    }
    .about-content {
        margin-left: 0;
        margin-bottom: 30px;

        h3 {
            font-size: 16px;
            margin-bottom: 15px;
        }
        .about-btn {
            margin-top: 25px;

            .play-btn {
                font-size: 14px;
                margin-left: 15px;
                padding-left: 52px;

                i {
                    width: 46px;
                    height: 46px;
                    line-height: 46px;
                    font-size: 14px;
                }
            }
        }
    }
    .about-img-2 {
        margin-bottom: 30px;
    }
    .about-content-two {
        h3 {
            font-size: 17px;
        }
        p {
            margin-bottom: 25px;
        }
        .about-list {
            margin-right: 15px;
            margin-bottom: 30px;
        }
    }
    .about-btn-two {
        .about-more-btn {
            padding: 10px 20px;
        }
        .contact-us-btn {
            padding: 10px 20px;
            margin-left: 15px;
        }
    }
    .about-widget-img {
        margin-top: 30px;
        margin-bottom: 30px;
    }
    .about-widget-content {
        margin-bottom: 30px;
        
        h3 {
            font-size: 17px;
        }
        p {
            margin-bottom: 25px;
        }
        .about-widget-list {
            ul {
                li {
                    p {
                        font-size: 16px;
                    }
                }
            }
        }
    }
    
    /* About Area CSS End */
    .schedule-content {
        max-width: 100%;
        background-color: transparent;
        padding: 0 15px;

        h2 {
            font-size: 24px;
        }
    }
    .schedule-content-shape {
        display: none;
    }
    .serve-content {
        p {
            margin-top: 5px;
            margin-bottom: 25px;
        }
    }
    
    /* Counter Area CSS */
    .counter-area {
        background-image: none;
        background-color: #fdeae2;
        padding-top: 50px;
        padding-bottom: 20px;
    }
    .single-counter {
        margin-top: 0;
        margin-bottom: 10px;
        max-width: 180px;
        margin-left: auto;
        margin-right: auto;

        i {
            font-size: 40px;
        }
        .content {
            h3 {
                font-size: 35px;
            }
        }
    }
    .counter-area-two {
        background-image: none;
        background-color: #2966be;
        height: auto;
        padding-bottom: 20px;
    }
    .counter-shape-top {
        display: none;
    }
    .counter-card {
        margin-bottom: 20px;

        &::before {
            display: none;
        }
        h3 {
            font-size: 34px;
        }
    }
    /* Counter Area CSS End */
    .progress-card {
        padding: 50px;

        h3 {
            font-size: 34px;
        }
    }
    /* Seo Area CSS */
    .seo-area {
        &::before {
            display: none;
        }
    }
    .seo-width {
        max-width: 100%;
        padding: 50px 15px 0 15px;

        h2 {
            font-size: 24px;
        }
    }
    .seo-form {
        margin-bottom: 30px;

        .seo-form-area {
            border-radius: 0;

            .seo-form-option {
                border-radius: 0;
                padding: 7px 15px 7px 15px;
            }
            .form-group {
                margin-bottom: 10px;
                border-bottom: 1px solid rgba(0, 0, 0, 0.411);

                .form-control {
                    &.form-border {
                        border-right: none;
                    }
                }
            }
        }
    }
    .seo-img {
        margin-left: auto;
        margin-right: auto;
        text-align: center;
    }
    /* Seo Area CSS End */

    /* Service Area CSS */
    .services-area {
        .col-lg-4 {
            &:nth-child(1) {
                .services-card-into {
                    margin-top: 0;
                }
            }
            &:nth-child(3) {
                margin-top: 0;
            }
        }
    }
    .services-card-into {
        .services-dots {
            display: none;
        }
    }
    .services-area-two {
        padding-bottom: 30px;
    }
    .services-item {
        &::before {
            display: none;
        }
    }
    .services-area-three {
        .section-title {
            p {
                margin-bottom: 30px;
            }
        }
    }
    .services-item-two {
        padding: 0 0 30px 0;
    }
    .services-shape {
        display: none;
    } 
    .service-two-shape {
        display: none;
    } 
    .service-another {
        padding-top: 50px;
    }
    .service-another-shape {
        display: none;
    }
    /* Service Area CSS End */

    /* Service Details Area CSS */
    .services-content {
        h2 {
            font-size: 26px;
            margin-bottom: 10px;
        }
        p {
            margin-bottom: 20px; 
        }
    }
    .content-widget-area {
        .content-widget-text {
            h2 {
                font-size: 26px;
                margin-bottom: 15px;
            }
            p {
                margin-bottom: 20px;
            }
        }
    }
    .services-widget-list {
        margin-top: 30px;
        margin-bottom: 0;

        &::before {
            display: none;
        }
        ul {
            li {
                padding: 0;
                margin-right: auto;
                margin-left: auto;

                &:nth-child(1) {
                    top: 0;
                }
                &:nth-child(2) {
                    top: 0;
                }
                &:nth-child(3) {
                    top: 0;
                }
                &:nth-child(4) {
                    top: 0;
                }
                span {
                    font-size: 15px;
                    width: 55px;
                    height: 55px;
                    line-height: 45px;
                }
                .content {
                    h3 {
                        font-weight: 500;
                        line-height: 1;
                        font-size: 16px;
                    }
                }
            }
        }
    }
    .content-widget-img {
        margin-bottom: 30px;
    }
    /* Service Details Area CSS End */
   
    /* History Area CSS */
    .history-list {
        margin-top: 30px;
        margin-bottom: 0;
        max-width: 100%;

        &::before {
            display: none;
        }
        ul {
            li {
                margin-right: 30px;
                margin-bottom: 25px;
            }
        }
    }
    .history-content {
        h2 {
            font-size: 24px;
            margin-bottom: 10px;
        }
        p {
            margin-bottom: 10px;
        }
    }
    .history-img {
        margin-bottom: 0;
        margin-left: auto;
        margin-right: auto;
    }
    /* History Area CSS End */

    /* Courses Area CSS */
    .courses-card {
        padding: 30px 20px;

        .images {
            position: inherit;
            top: 0;
            right: 0;
            border-radius: 0;
            background-color: #f1f1f1;
            margin-bottom: 20px;
            text-align: center;

            .rate {
                right: 175px;

                a {
                    font-size: 14px;
                    width: 50px;
                    height: 50px;
                    line-height: 50px;
                }
            }
        }
    }
    /* Courses Area CSS End */
    
    /* Work Area CSS */
    .work-area {
        background-image: none;
        position: relative;

        &::before {
            content: '';
            position: absolute;
            z-index: -1;
            top: 0;
            left: 0;
            right: 0;
            width: 100%;
            height: 100%;
            background-color: #fdeae2;
        }
    }
    .work-shape {
        display: none;
    }
    .work-area-two {
        background-image: none;
        position: relative;

        &::before {
            content: '';
            position: absolute;
            z-index: -1;
            top: 0;
            left: 0;
            right: 0;
            width: 100%;
            height: 100%;
            background-color: #fdeae2;
        }
    }
    .work-img-2 {
        margin-bottom: 30px;
    }
    /* Work Area CSS End */

    /* Mission Area CSS */
    .mission-content {
        margin-left: auto;
        margin-right: auto;

        p {
            margin-bottom: 30px;
        }
        .mission-list {
            margin-right: 15px;
            margin-bottom: 30px;
        }
    }
    .mission-img {
        margin-left: auto;
        margin-right: auto;
    }
    .mission-shape {
        display: none;
    }
    /* Mission Area CSS End */

    /* Pricing Area CSS */
    .pricing-area {
        .section-title {
            margin-bottom: 30px;
        }
    }
    .pricing-shape {
        display: none;
    }
    .price-width {
        margin-top: 35px;
        max-width: 100%;
        padding:50px 15px 20px 15px;
    }
    .pricing-card {
        margin-bottom: 30px;

        .pricing-card-into {
            padding: 0 30px 20px;

            .pricing-icon {
                width: 65px;
                height: 65px;
                font-size: 24px;
                line-height: 65px;
            }
            h3 {
                font-size: 18px;
                padding: 7px;
            }
        }
        .price-rate {
            h2 {
                font-size: 35px;
                line-height: 1;
                margin-bottom: 0;
            }
        }
        .purchase-btn {
            margin-top: 15px;
            padding: 9px 24px;
        }
    }
    /* Pricing Area CSS End */

    /* Team Area CSS */
    .team-area {
        &.pt-100 {
            padding-top: 0;
        }
    }
    .team-shape {
        display: none;
    }
    .team-two-shape {
        display: none;
    }
    .team-card {
        margin-top: 30px;

        .content {
            transform: scale(1);
        }
    }
    .team-rotated {
        transform: rotate(0deg);
        margin-top: 30px;
    }
    .team-rotated-2 {
        transform: rotate(0deg);
        margin-top: 30px;
    }
    .team-area-two {
        position: relative;

        .section-title {
            h2 {
                max-width: 590px;
                margin-left: auto;
                margin-right: auto;
            }
        }
        .owl-nav {
            .owl-prev {
                position: absolute;
                top: 40%;
                left: 0;
            }
            .owl-next {
                position: absolute;
                top: 40%;
                right: 0;
            }
        }
    }
    /* Team Area CSS End */

    /* Testimonial Area CSS */
    .testimonial-area {
        background-image: none;
        background-color: #fdfdfd;
    }
    .testimonial-area-mb {
        margin-bottom: 0;
    }
    .testimonial-list {
        height: 350px;
        border: 15px solid #ffe8df;

        .list-img-1 {
            img {
                max-width: 90px;
            }
        }
        .list-img-2 {
            top: 32%;

            img {
                max-width: 75px;
            }
        }
        .list-img-3 {
            left: -10px;

            img {
                max-width: 80px;
            }
        }
        .list-img-5 {
            right: 15%;
        }
    }
    .testimonial-title {
        margin-top: 30px;

        h2 {
            font-size: 24px;
            margin-bottom: 20px;
            margin-left: 0;
            text-align: center;
        }
    }
    .testimonial-slider {
        margin-left: auto;
        margin-right: auto;
    }
    .testimonial-item {
        p {
            font-size: 17px;
        }
    }
    .testimonial-title-2 {
        h2 {
            font-size: 25px;
        }
    }
    .testimonial-content {
        height: 400px;

        .testimonial-content3 {
            top: 50%;
        }
        .testimonial-content4 {
            top: 55%;
        }
        .testimonial-shape1 {
            top: 37%;
        }
        .testimonial-shape2 {
            top: 35%;
        }
    }
    .testimonial-slider-area {
        background-image: none;
        background-color: #ff3900;
        padding: 40px 30px;

        .owl-nav {
            .owl-prev {
                bottom: -40px;
                left: 38% !important;
            }
            .owl-next {
                right: 40% !important;
                bottom: -40px;
            }
        }
    }
    .testimonial-item-2 {
        p {
            font-size: 17px;
        }
    }
    .testimonial-text {
        background-image: none;
        width: 150px;
        height: 140px;
        background-color: #ffffff;
        box-shadow: 0 0 15px #f1f1f1;
        border-radius: 50px 30px 50px 20px;

        h3 {
            font-size: 28px;
        }
        span {
            font-size: 14px;
        }
    }
    .testimonial-widget-item {
        p {
            font-size: 18px;
        }
    }
    /* Testimonial Area CSS End */

    /* Blog Area CSS */
    .blog-area {
        background-image: none;
        background-color: #fdeae2;
    }
    .blog-title {
        margin-bottom: 20px;
    }
    .blog-btn {
        float: none;
        margin-top: 0;
        margin-bottom: 25px;
    }
    .blog-card {
        .content {
            padding: 20px 15px;

            ul {
                li {
                    margin-right: 15px;
                }
            }
            h3 {
                font-size: 20px;
            }
        }
    }
    .blog-shape {
        display: none;
    }
    .blog-area-widget {
        .title {
            h2 {
                font-size: 26px;
            }
        }
    }
    /* Blog Area CSS End */

    /* Blog Details Area CSS */
    .blog-article {
        .article-content {
            h2 {
                font-size: 26px;
            }
            .blockquote {
                p {
                    font-size: 18px;
                    margin-top: 10px;
                    margin-right: 0; 
                    margin-left: 0; 
                }
            }
        }
        .another-content {
            h2 {
                font-size: 26px;
                margin-bottom: 15px;
            }
            p {
                margin-bottom: 20px;
            }
            .content-img {
                margin-bottom: 20px;
                padding: 0 7px;

                .col-6 {
                    padding-left: 7px !important;
                    padding-right: 7px !important;
                }
            }
        }
        .blog-article-share {
            .social-icon {
                float: none;
                margin-bottom: 5px;
            }
        }
        .article-author {
            padding: 60px 40px 30px;

            ul {
                li {
                    padding-left: 0;

                    img {
                        position: inherit;
                    }
                    h3 {
                        color: #2a284f;
                    }
                    span {
                        color: #2a284f;
                    }
                    p {
                        margin-top: 0;
                    }
                }
            }
            .author-social-link {
                position: inherit;
                top: 0;
                right: auto;
                left: 0;

                .social-icon {
                    float: none;

                    li {
                        a {
                            color: #ff3900;
                        }
                    }
                }
            }
        }
        .article-post {
            &::before {
                display: none;
            }
            .article-post-share {
                margin-bottom: 10px;
            }
        } 
        .comments-wrap {
            .title {
                margin-bottom: 0;
            }
            ul {
                li {
                    padding-left: 0;
                    margin-bottom: 0;

                    img {
                        position: inherit;
                        top: 0;
                        left: 15px;
                        margin-bottom: 20px;
                    }
                    h3 {
                        margin-left: 15px;
                    }
                    span {
                        margin-left: 15px;
                    }
                    p {
                        margin-left: 15px; 
                    }
                    a {
                        position: inherit;
                        top: 0;
                        right: auto;
                        margin-left: 15px;
                        margin-top: 10px;
                    }
                    &.ml-30 {
                        margin-left: 0;
                    }
                }
            }
        }
    }
    /* Blog Details Area CSS End */

    .data-table-area {
        padding-top: 50px;
        padding-bottom: 50px;
        background-image: none;
        background-color: #2966be;
    }
    .data-table-content {
        margin-top: 0;

        h2 {
            font-size: 24px;
        }
    }
    .project-area {
        padding-top: 50px;
        padding-bottom: 40px;
    }
    .project-shape {
        display: none;
    }
    .project-content {
        h2 {
            font-size: 24px;
            margin-bottom: 20px;
        }
    }

    /* Contact Area CSS */
    .contact-card {
        padding: 10px 30px 40px 20px;
    }
    .contact-section {
        background-color: #fef4f0;

        &::before {
            display: none;
        }
    }
    .contact-img {
        margin-left: auto;
        margin-right: auto;
    }
    .contact-wrap {
        margin-left: auto;
        margin-right: auto;
    }
    .contact-form {
        padding: 30px 20px 25px 20px;
        max-width: 800px;

        .section-title {
            margin-bottom: 20px;

            h2 {
                font-size: 26px;
            }
        }
    }
    /* Contact Area CSS End */

    /* User All */
    .user-all-form {
        .contact-form {
            .agree-label {
                label {
                    font-size: 15px;
                }
            }
            .forget {
                margin-bottom: 10px;
                float: left;
                font-size: 15px;
            }
        }
    }
    /* User All End */
    
    /* Footer Area CSS */
    .footer-img {
        img {
            max-width: 130px;
        }
    }
    .footer-social-icon {
        margin-bottom: 30px;

        .social-link {
            float: none;
        }
    }
    .footer-widget.pl-5, .footer-widget.px-5 {
        padding-left: 0 !important;
    }
    .copy-right-area {
        .copy-right-text {
            p {
                padding: 0 15px;
                text-align: center;
            }
        }
    }
    .copy-right-list {
        float: none;
        text-align: center;
        margin-top: 7px;
    }
    /* Footer Area CSS End */

    /* Error Area CSS */
    .error-area {
        height: 100vh;
        padding-top: 70px;
        padding-bottom: 70px;

        .error-content {
            h3 {
                margin-bottom: 10px;
                font-size: 25px;
            }
            p {
                font-size: 16px;
               padding: 0 15px;
            }
        }
    }
    /* Error Area CSS End */

    /* Coming Soon */
    .coming-soon-area {
        .coming-soon-content {
            padding-top: 30px;
            padding-bottom: 20px;
            padding-left: 15px;
            padding-right: 15px;

            h1 {
                font-size: 30px;
                line-height: 1.1;
                margin-top: 0;
            }
            p {
                font-size: 14px;
                margin-top: 15px;
            }
            #timer {
                margin-top: 10px;

                div {
                    font-size: 30px;
                    margin-left: 5px;
                    margin-right: 5px;

                    span {
                        font-size: 14px;
                        margin-top: -5px;
                    }
                    &::before {
                        display: none;
                    }
                }
            }
            .newsletter-form {
                margin-top: 20px;
                margin-bottom: 20px;

                .input-newsletter {
                    height: 55px;
                    padding-left: 13px;
                    font-size: 15px;
                }
                button {
                    position: relative;
                    font-size: 14px;
                    margin-top: 15px;
                    width: 100%;
                    height: 50px;
                }
            }
        }
    }
    /* Coming Soon End */

    .pagination-area {
        margin-top: 0;

        .page-numbers {
            margin-left: 0;
            margin-right: 0;
        }
    }

}

@media only screen and (min-width : 576px) and (max-width : 767px) {

    .about-area-two {
        .container-fluid {
            width: 540px;
        }
    }
    .about-widget-area {
        .container-fluid {
            width: 540px;
        }
    }
    .seo-width {
        h2 {
            text-align: center;
        }
    }
     
    .serve-area {
        .container-fluid {
            width: 540px;
        }
    }
    .serve-img {
        text-align: center;
    }
    .history-list {
        ul {
            li {
                margin-right: 15px;
                
                i {
                    font-size: 30px;
                    width: 55px;
                    height: 55px;
                    background-color: #ffffff;
                    line-height: 45px;
                }
                .content {
                    h3 {
                        font-size: 18px;
                    }
                    span {
                        font-size: 14px;
                    }
                }
            }
        }
    }

    .services-left {
        margin-bottom: 0;
    }
    .services-widget-list {
        margin-top: 80px;
        margin-bottom: 0;

        &::before {
            display: block;
            top: 35px;
        }
        ul {
            li {
                padding: 0;
                margin-right: auto;
                margin-left: auto;

                &:nth-child(1) {
                    top: -10px;
                }
                &:nth-child(2) {
                    top: -75px;
                }
                &:nth-child(3) {
                    top: -10px;
                }
                &:nth-child(4) {
                    top: -75px;
                }
            }
        }
    }
    
    .courses-card {
        padding: 30px 100px 30px 30px;

        .images {
            position: absolute;
            top: 0;
            right: 0;
            background-color: #f1f1f1;
            border-radius: 0 0 0 100px;

            .rate {
                position: absolute;
                top: 10px;
                right: 130px;
            }
        }
    }
    .progress-card {
        padding: 40px 50px;
    }
    .team-area {
        .section-title {
            p {
                max-width: 465px;
            }
        }
    }
    .testimonial-slider-area {
        background-image: none;
        background-color: #ff3900;
        padding: 50px 30px;
        
        .owl-nav {
            .owl-prev {
                bottom: -10px;
                left: 50% !important;
            }
            .owl-next {
                right: 38% !important;
                bottom: -10px;
            }
        }
    }
    .contact-section {
        .container-fluid {
            width: 540px;
        }
    }
    .footer-social-icon {
        margin-bottom: 15px;
    }
    /* User All */
    .user-all-form {
        .contact-form {
            .forget {
                margin-bottom: 0;
                float: right;
            }
        }
    }
    /* User All End */

}

@media only screen and (min-width : 768px) and (max-width : 991px) {

    /* Default CSS */
    body {
        font-size: 14px;
    }
    .ptb-100 {
        padding-top: 50px;
        padding-bottom: 50px;
    }
    .pt-100 {
        padding-top: 50px;
    }
    .ptb-70 {
        padding-top: 50px;
        padding-bottom: 20px;
    }
    .pb-100 {
        padding-bottom: 50px;
    }
    .pb-70 {
        padding-bottom: 20px;
    }
    .pt-45 {
        padding-top: 30px;
    }
    .default-btn {
        margin: 5px;
        padding: 8px 45px 8px 15px;

        i {
            width: 30px;
            height: 30px;
            line-height: 30px;
            font-size: 16px;
        }
    }
    .section-title {
        span {
            font-size: 12px;
        }
        h2 {
            font-size: 24px;
        }
        p {
            padding-top: 10px;
        }
    }
    /* Default CSS End */

    /* Navbar CSS */
    .mobile-nav {
        .logo {
            img {
                max-width: 150px;
                height: auto;
                margin-top: -5px;
            }
        }
    }
    .d-in-line {
        display: none;
    }
    .side-nav-responsive {
        display: block;
    }
    .search-overlay {
        .search-form {
            form {
                .input-search {
                    height: 50px;
                    padding: 0 0 0 15px;
                }
            }
        }
    }
    .search-overlay {
        .search-form {
            max-width: 100%;
            width: 100%;

            form {
                .input-search {
                    height: 50px;
                    padding: 0 0 0 15px;
                }
                button {
                    position: absolute;
                    right: 5px;
                    top: 5px;
                    width: 40px;
                    height: 40px;
                    font-size: 18px;
                    line-height: 35px;
                }
            }
        }
    }
    .side-nav-responsive {
        .side-nav-inner {
            .side-nav {
                text-align: center;

                .side-item {
                    padding-left: unset;
                    margin: 0 10px;
                    text-align: center;
                }
            }
        }
    }
    /* Navbar CSS End */

    /* Main Banner CSS */
    .banner-content {
        padding-top: 110px;
        padding-bottom: 20px;

        .title {
            height: 32px;
            line-height: 30px;
            padding-right: 10px;

            i {
                width: 30px;
                height: 30px;
                line-height: 30px;
                font-size: 16px;
            }
            span {
                top: -1px;
            }
        }
        h1 {
            margin-top: 15px;
            margin-bottom: 10px;
            font-size: 40px;
        }
        p {
            font-size: 16px;
        }
        .banner-btn {
            margin-top: 20px;

            .get-btn {
                margin: 5px;
                padding: 8px 45px 8px 15px;

                i {
                    width: 30px;
                    height: 30px;
                    line-height: 30px;
                    font-size: 16px;
                }
            }
            .call-btn {
                margin: 5px;
                padding: 8px 15px 8px 45px;

                i {
                    width: 30px;
                    height: 30px;
                    line-height: 30px;
                    font-size: 16px;
                }
            }
        } 
    }
    .banner-shape {
        display: none;
    }
    .banner-shape-right {
        display: none;
    }
    .banner-img {
        position: inherit;
        top: 0;
        margin-bottom: 50px;

        img {
            max-width: 100%;
            height: auto;
        }
    }
    .banner-area-two {
        background-image: none;
        background-color: #2966be;
        height: auto;

        .container-fluid {
            width: 720px;
        }
        &::before {
            display: none;
        }
    }
    .banner-content-two {
        padding-top: 140px;
        padding-bottom: 30px;

        h1 {
            font-size: 40px;
        }
        .banner-content-btn {
            margin-top: 30px;

            .learn-btn {
                padding: 8px 45px 8px 15px;

                i {
                    width: 30px;
                    height: 30px;
                    line-height: 30px;
                    font-size: 16px;
                }
            }
            .play-on-btn {
                margin-left: 15px;
                top: 0px;
            }
        }
    }
    .banner-img-2 {
        margin-top: 0;
        padding-bottom: 70px;
    }
    .banner-bottom-shape {
        display: none;
    }
    .banner-area-three {
        .container-fluid {
            width: 720px;
        }
    }
    .banner-content-three {
        padding-top: 140px;
        padding-bottom: 30px;

        h1 {
            font-size: 34px;
           margin-bottom: 20px;
        }
        p {
            font-size: 16px;
        }
        .banner-three-btn {
            margin-top: 20px;

            .explore-btn {
                padding: 8px 16px;
            }
            .play-two-btn {
                margin-left: 15px;
                top: -5px;
            }
        }
    }
    .banner-img-3 {
        position: inherit;
        bottom: 0;
        right: 0;
        left: 0;
        text-align: center;

        img {
            max-width: 100%;
            height: auto;
        }
    }
    .banner-three-shape {
        display: none;
    }
    /* Main Banner CSS End */

    /* Inner Banner CSS */
    .inner-banner {
        background-image: none;
        background-color: #0149b1;
        height: auto;

        &::before {
           display: none;
        }
        .inner-title {
            padding-top: 150px;
            padding-bottom: 90px;

            h3 {
                font-size: 30px;
                margin-bottom: 0;
            }
            .inner-list {
                margin-top: 10px;
            }
        }
        .inner-banner-shape {
            display: none;
        }
    }
    /* Inner Banner CSS End */

    /* Brand Area CSS */
    .brand-area {
        padding-bottom: 30px;

        .owl-nav {
            right: 0;
            left: 0;
            top: auto;
            bottom: -40px;
            margin-bottom: 0;
        }
    }
    .brand-max {
        width: 100%;
        padding-left: 15px;
        padding-right: 15px;
    }
    .brand-slider {
        max-width: 100%;
        padding-bottom: 0;
    }
    /* Brand Area CSS End */

    /* About Area CSS */
    .about-area {
        .container-fluid {
            width: 720px;
        }
    }
    .about-widget-area {
        .container-fluid {
            width: 720px;
        }
    }
    .about-widget-content {
        margin-bottom: 30px;
        
        p {
            margin-bottom: 25px;
        }
    }
    .about-shape {
        display: none;
    }
    .about-content {
        margin-left: 0;
        margin-bottom: 30px;

        h3 {
            font-size: 16px;
            margin-bottom: 15px;
        }
        .about-btn {
            margin-top: 25px;
            
            .play-btn {
                font-size: 14px;
                margin-left: 15px;
                padding-left: 52px;

                i {
                    width: 46px;
                    height: 46px;
                    line-height: 46px;
                    font-size: 14px;
                }
            }
        }
    }
    .about-area-two {
        .container-fluid {
            width: 720px;
        }
    }
    .about-img-2 {
        margin-bottom: 30px;
    }
    .about-content-two {
        h3 {
            font-size: 17px;
        }
        p {
            margin-bottom: 25px;
        }
        .about-list {
            margin-right: 15px;
            margin-bottom: 30px;
        }
    }
    /* About Area CSS End */

    .seo-width {
        h2 {
            text-align: center;
        }
    }

    /* Counter Area CSS */
    .counter-area {
        background-image: none;
        background-color: #fdeae2;
        padding-top: 50px;
        padding-bottom: 20px;

        .container {
            width: 540px;
        }
    }
    .single-counter {
        margin-top: 0;
        margin-bottom: 10px;
        max-width: 180px;
        margin-left: auto;
        margin-right: auto;

        i {
            font-size: 40px;
        }
        .content {
            h3 {
                font-size: 35px;
            }
        }
    }
    .counter-area-two {
        background-image: none;
        background-color: #2966be;
        height: auto;
        padding-bottom: 20px;
    }
    .counter-shape-top {
        display: none;
    }
    .counter-card {
        margin-bottom: 20px;

        &::before {
            display: none;
        }
        h3 {
            font-size: 34px;
        }
    }
    /* Counter Area CSS End */
    .progress-card {
        padding: 50px;

        h3 {
            font-size: 34px;
        }
    }
    /* Seo Area CSS */
    .seo-area {
        &::before {
            display: none;
        }
    }
    .seo-width {
        max-width: 100%;
        padding: 50px 15px 0 15px;

        h2 {
            font-size: 24px;
        }
    }
    .seo-form {
        margin-bottom: 30px;

        .seo-form-area {
            .default-btn {
                margin-left: 0;
            }
        }
    }
    .seo-img {
        margin-left: auto;
        margin-right: auto;
        text-align: center;
    }
    /* Seo Area CSS End */

    .schedule-content {
        max-width: 100%;
        background-color: transparent;
        padding: 0 15px;

        h2 {
            font-size: 24px;
        }
    }
    .schedule-content-shape {
        display: none;
    }
    .serve-content {
        p {
            margin-top: 5px;
            margin-bottom: 25px;
        }
    }
    .schedule-shape {
        display: none;
    }

    /* Service Area CSS */
    .services-area {
        .col-lg-4 {
            &:nth-child(1) {
                .services-card-into {
                    margin-top: 0;
                }
            }
            &:nth-child(3) {
                margin-top: 0;
            }
        }
    }
    .services-card-into {
        .services-dots {
            display: none;
        }
    }
    .services-shape {
        display: none;
    } 
    .services-area-two {
        padding-bottom: 30px;
    }
    .services-item {
        &::before {
            display: none;
        }
    }
    .services-shape {
        display: none;
    } 
    .service-two-shape {
        display: none;
    } 
    .services-area-three {
        .section-title {
            p {
                margin-bottom: 30px;
            }
        }
    }
    .services-item-two {
        padding: 0 0 30px 0;
    }
    .service-another {
        padding-top: 50px;
    }
    .service-another-shape {
        display: none;
    }
    .content-widget-img {
        margin-bottom: 30px;
    }
    /* Service Area CSS End */

    .services-content-card {
        h3 {
            font-size: 18px;
        }
    }
    .services-widget-list {
        margin-bottom: -50px;
        ul {
            li {
                margin-right: 0;
            }
        }
    }
    
    .history-list {
        margin-bottom: 20px;
        ul {
            li {
                display: inline-block;
                margin-right: 35px;
            }
        }
    }
    .history-content {
        h2 {
            font-size: 24px;
            margin-bottom: 15px;
        }
        p {
            margin-bottom: 10px;
        }
    }
    .history-img {
        margin-bottom: 0;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
    }

    .serve-area {
        .container-fluid {
            width: 720px;
        }
    }
    .serve-content {
        margin-left: auto;
        margin-right: auto;
    }
    .courses-card {
        h3 {
            max-width: 465px;
            color: #2a284f;
        }
    }
    /* Work Area CSS */
    .work-area {
        background-image: none;
        position: relative;

        &::before {
            content: '';
            position: absolute;
            z-index: -1;
            top: 0;
            left: 0;
            right: 0;
            width: 100%;
            height: 100%;
            background-color: #fdeae2;
        }
    }
    .work-shape {
        display: none;
    }
    .work-area-two {
        background-image: none;
        position: relative;

        &::before {
            content: '';
            position: absolute;
            z-index: -1;
            top: 0;
            left: 0;
            right: 0;
            width: 100%;
            height: 100%;
            background-color: #fdeae2;
        }
    }
    .work-img-2 {
        margin-bottom: 30px;
        text-align: center;
    }
    .work-item-list {
        max-width: 520px;
        margin-left: auto;
        margin-right: auto;
    }
    .work-item-list-2 {
        max-width: 520px;
        margin-left: auto;
        margin-right: auto;
    }
    /* Work Area CSS End */

    /* Mission Area CSS */
    .mission-content {
        margin-left: auto;
        margin-right: auto;

        p {
            margin-bottom: 30px;
        }
        .mission-list {
            margin-right: 15px;
            margin-bottom: 30px;
        }
    }
    .mission-img {
        margin-left: auto;
        margin-right: auto;
        text-align: center;
    }
    .mission-shape {
        display: none;
    }
    /* Mission Area CSS End */

    /* Pricing Area CSS */
    .pricing-area {
        .section-title {
            margin-bottom: 30px;
        }
    }
    .pricing-shape {
        display: none;
    }
    .price-width {
        margin-top: 35px;
        max-width: 100%;
        padding:50px 15px 20px 15px;
    }
    .pricing-card {
        margin-bottom: 30px;

        .pricing-card-into {
            padding: 0 30px 20px;

            .pricing-icon {
                width: 65px;
                height: 65px;
                font-size: 24px;
                line-height: 65px;
            }
            h3 {
                font-size: 18px;
                padding: 7px;
            }
        }
        .price-rate {
            h2 {
                font-size: 35px;
                line-height: 1;
                margin-bottom: 0;
            }
        }
        .purchase-btn {
            margin-top: 15px;
            padding: 9px 24px;
        }
    }
    /* Pricing Area CSS End */

    /* Team Area CSS */
    .team-area {
        &.pt-100 {
            padding-top: 0;
        }
    }
    .team-shape {
        display: none;
    }
    .team-two-shape {
        display: none;
    }
    .team-card {
        margin-top: 30px;

        .content {
            transform: scale(1);
        }
    }
    .team-rotated {
        transform: rotate(0deg);
        margin-top: 30px;
    }
    .team-rotated-2 {
        transform: rotate(0deg);
        margin-top: 30px;
    }
    .team-area-two {
        position: relative;

        .section-title {
            h2 {
                max-width: 590px;
                margin-left: auto;
                margin-right: auto;
            }
        }
        .owl-nav {
            .owl-prev {
                position: absolute;
                top: 40%;
                left: 0;
            }
            .owl-next {
                position: absolute;
                top: 40%;
                right: 0;
            }
        }
    }
    /* Team Area CSS End */

    /* Testimonial Area CSS */
    .testimonial-area {
        background-image: none;
        background-color: #fdfdfd;
    }
    .testimonial-area-mb {
        margin-bottom: 0;
    }
    .testimonial-list {
        border: 15px solid #ffe8df;
        margin-left: auto;
        margin-right: auto;
       
    }
    .testimonial-title {
        margin-top: 30px;

        h2 {
            font-size: 24px;
            margin-bottom: 20px;
            margin-left: 0;
            text-align: center;
        }
    }
    .testimonial-slider {
        margin-left: auto;
        margin-right: auto;
    }
    .testimonial-item {
        p {
            font-size: 17px;
        }
    }
    .testimonial-content {
        height: 450px;

        .testimonial-content1 {
            left: 10%;
        }
        .testimonial-content2 {
            right: 10%;
        }
        .testimonial-content3 {
            top: 55%;
            left: 10%;
        }
        .testimonial-content4 {
            right: 10%;
        }
        .testimonial-shape1 {
            top: 43%;
            left: 20%;
        }
        .testimonial-shape2 {
            top: 40%;
        }
    }
    .testimonial-slider-area {
        background-image: none;
        background-color: #ff3900;
        padding: 40px 30px;
        margin-bottom: 50px;
    }
    .testimonial-title-2 {
        h2 {
            font-size: 30px;
        }
    }
    .testimonial-item-2 {
        p {
            font-size: 17px;
        }
    }
    .testimonial-widget-item {
        p {
            font-size: 18px;
        }
    }
    /* Testimonial Area CSS End */

    /* Blog Area CSS */
    .blog-area {
        background-image: none;
        background-color: #fdeae2;
    }
    .blog-bg2 {
        &::before {
            display: none;
        }
    }
    .blog-title {
        margin-bottom: 20px;
    }
    .blog-btn {
        float: none;
        margin-top: 0;
        margin-bottom: 25px;
    }
    .blog-card {
        .content {
            padding: 20px 15px;

            ul {
                li {
                    margin-right: 15px;
                }
            }
            h3 {
                font-size: 20px;
            }
        }
    }
    .blog-shape {
        display: none;
    }
    .project-area {
        padding-top: 50px;
        padding-bottom: 40px;
    }
    .project-shape {
        display: none;
    }
    .project-content {
        h2 {
            font-size: 24px;
            margin-bottom: 20px;
        }
    }
    /* Blog Area CSS End */

    .data-table-area {
        padding-bottom: 50px;
        padding-bottom: 50px;
        background-image: none;
        background-color: #2966be;
    }
    .data-table-content {
        margin-top: 0;

        h2 {
            font-size: 24px;
        }
    } 

    /* Contact Area CSS */
    .contact-card {
        padding: 10px 30px 40px 20px;
    }
    .contact-section {
        background-color: #fef4f0;

        .container-fluid {
            width: 720px;
        }
        &::before {
            display: none;
        }
    }
    .contact-img {
        margin-left: auto;
        margin-right: auto;
    }
    .contact-wrap {
        margin-left: auto;
        margin-right: auto;
    }
    .contact-form {
        padding: 30px 20px 25px 20px;

        .section-title {
            margin-bottom: 20px;

            h2 {
                font-size: 26px;
            }
        }
    }
    /* Contact Area CSS End */

    /* Footer Area CSS */
    .footer-img {
        img {
            max-width: 130px;
        }
    }
    .footer-social-icon {
        margin-bottom: 30px;

        .social-link {
            float: none;
        }
    }
    .footer-widget.pl-5, .footer-widget.px-5 {
        padding-left: 0 !important;
    }
    .copy-right-area {
        .copy-right-text {
            p {
                padding: 0 15px;
                text-align: center;
            }
        }
    }
    .copy-right-list {
        float: none;
        text-align: center;
        margin-top: 7px;
    }
    /* Footer Area CSS End */

    /* Error Area CSS */
    .error-area {
        height: 100vh;
        padding-top: 70px;
        padding-bottom: 70px;

        .error-content {
            h3 {
                margin-bottom: 10px;
                font-size: 25px;
            }
            p {
                font-size: 16px;
               padding: 0 15px;
            }
        }
    }
    /* Error Area CSS End */

    /* Coming Soon */
    .coming-soon-area {
        .coming-soon-content {
            padding-top: 30px;
            padding-bottom: 20px;
            padding-left: 15px;
            padding-right: 15px;

            h1 {
                font-size: 40px;
                line-height: 1.1;
                margin-top: 0;
            }
            p {
                font-size: 14px;
                margin-top: 15px;
            }
            #timer {
                margin-top: 10px;

                div {
                    font-size: 35px;
                    margin-left: 15px;
                    margin-right: 15px;

                    span {
                        font-size: 14px;
                        margin-top: -5px;
                    }
                    &::before {
                        display: none;
                    }
                }
            }
        }
    }
    /* Coming Soon End */

}

@media only screen and (min-width : 992px) and (max-width : 1199px) {

    .main-nav {
        nav {
            .navbar-nav {
                .nav-item {
                    a {
                        margin-left: 8px;
                        margin-right: 8px;
                    }
                }
            }
            .side-nav {
                padding: 10px 0 3px;
                margin-left: 10px;

                .side-item {
                    padding-left: 10px;

                    .search-box-2 {
                        width: 180px;

                        .form-control {
                            width: 180px;
                            border-radius: 5px;
                            font-size: 14px;
                        }
                        .search-btn {
                            right: 3px;
                            width: 40px;
                            font-size: 16px;
                        }
                    } 
                    .nav-menu-btn {
                        padding: 10px 16px;
                    }
                }
            }
        }
    }
    .navbar-light {
        .navbar-brand  {
            img {
                max-width: 250px;
            }
        }
    }
  
    .banner-shape-right {
        img {
            max-width: 330px;
        }
    }
    .banner-img {
        top: 205px;

        img {
            max-width: 690px;
        }
    }
    .banner-content {
        h1 {
            font-size: 65px;
        }
        p {
            max-width: 480px;
        }
    }
    .banner-content-two {
        padding-top: 200px;

        h1 {
            font-size: 50px;
        }
    }
    .banner-bottom-shape {
        .banner-dots2 {
            bottom: 25%;
        }
    }
    .banner-content-three {
        h1 {
            font-size: 54px;
        }
        p{
            max-width: 475px;
        }
    }
    .banner-img-3 {
        img {
            max-width: 520px;
            height: 540px;
        }
    }
    .content-widget-area {
        .content-widget-text {
            h2 {
                font-size: 23px;
            }
            ul {
                li {
                    font-size: 15px;

                    i {
                        margin-right: 5px;
                    }
                }
            }
        }
    }
    .services-widget-list {
        margin-bottom: -70px;

        ul {
            li {
                margin-right: 0;
            }
        }
    }
     
    .services-content-card {
        padding: 30px 15px;

        h3 {
            font-size: 17px;
        }
    }
    .progress-card {
        padding: 50px;

        p {
            font-size: 14px;
        }
    }
    .brand-slider {
        max-width: 900px;
    }
    .brand-area {
        border-bottom: 1px solid #e4e4e4;

        .owl-nav {
            right: -70px;
            top: -16px;
        }
    }
    .about-content {
        margin-left: 15px;
    }
    .about-shape {
        .shape-1 {
            img {
                max-width: 60px;
                height: 610px;
            }
        }
    }
    .about-content-two {
        .about-list {
            padding: 10px 10px 10px 40px;

            p {
                font-size: 15px;
            }
        }
    }
    .seo-form {
        .seo-form-area {
            .seo-form-option {
                padding: 7px 0 7px 15px;
            }
            .default-btn {
                margin-left: 0;
                padding: 12px 60px 12px 16px;
            }
        }
    }
    .schedule-shape {
        img {
            max-width: 100%;
        }
    }
   
    .work-shape {
        top: 250px;
    }
    .courses-card {
        h3 {
            max-width: 256px;
            color: #2a284f;
        }
    }

    .team-area-two {
        .owl-nav {
            .owl-prev {
                left: 0;
            }
            .owl-next {
                right: 0;
            }
        }
    }
    .testimonial-slider {
        margin-left: 0;
    }
    .testimonial-list {
        .list-img-3 {
            left: -5%;
        }
    }
    .testimonial-content {
        height: 520px;
        
        .testimonial-content1 {
            left: -8%;
        }
        .testimonial-content2 {
            right: -13%;
        }
        .testimonial-content3 {
            left: -8%;
        }
        .testimonial-content4 {
            right: -13%;
        }
        .testimonial-shape1 {
            top: 33%;
        }
        .testimonial-shape2 {
            top: 33%;
            left: 65px;
        }
    }
    .testimonial-slider-area {
        background-image: none;
        background-color: #ff3900;
        padding: 40px 30px;
        margin-left: 30px;
    }
    .testimonial-item-2 {
        p {
            font-size: 17px;
        }
    }
    .testimonial-title-2 {
        h2 {
            font-size: 30px;
        }
    }
     
    .blog-card {
        .content {
            padding: 20px 15px;

            ul {
                li {
                    margin-right: 5px;
                }
            }
        }
    }

    .faq-accordion {
        .accordion  {
            .accordion-title {
                font-size: 17px;
            }
        }
    }

    .footer-img {
       img {
           max-width: 130px;
       }
    }
    .footer-widget {
        .footer-list {
            li {
                font-size: 15px;
            }
        }
    }
    
}

@media only screen and (min-width : 1800px) {
    
    .banner-shape-right {
        position: absolute;
        top: 0;
        right: 0;

        img {
            max-width: 100%;
        }
    }
    .banner-content {
        padding-top: 255px;

        p {
            max-width: 720px;
        }
    }
    .banner-img {
        top: 155px;

        img {
            max-width: 1200px;
        }
    }
    .banner-bottom-shape {
        .banner-dots1 {
            top: 25%;
            left: 37%;
        }
        .banner-dots2 {
            right: 25%;
        }
    }
    .banner-content-three {
        padding-top: 240px;
        padding-bottom: 170px;
    }
    .banner-img-3 {
        img {
            max-width: 1040px;
            height: auto;
        }
    }
    .banner-bottom-shape {
        .shape-one {
            bottom: -40px;
        }
        .shape-two {
            bottom: -20px;
        }
        .shape-three {
            bottom: 0;
        }
    }
     
    .inner-banner {
        .inner-banner-shape {
            .shape-one {
                bottom: 0;
            }
            .shape-two {
                bottom: 25px;
            }
            .shape-three {
                bottom: 50px;
            }
        }
    }
     
    .about-shape {
        .shape-1 {
            top: 0;

            img {
                max-width: 100%;
                height: 740px;
            }
        }
    }
    .services-area-two {
        padding-top: 140px;
    }
    .work-shape {
        left: 0;
        right: 0;
        text-align: center;
    }
    .pricing-shape {
        .shape1 {
            left: 5%;
        }
        .shape2 {
            left: 10%;
        }
        .shape3 {
            left: 7%;
        }
        .shape4 {
            right: 15%;
        }
        .shape5 {
            right: 5%;
        }
        .shape6 {
            right: 7%;
        }
    }

}

@media only screen and (min-width : 1200px) {

    .container-max {
        max-width: 1300px !important;
        margin: 0 auto;
    } 
    .container-max-2 {
        max-width: 1460px !important;
        margin: 0 auto;
    } 
    .brand-max {
        max-width: 1240px;
        margin: 0 auto;
        border-bottom: 1px solid #e4e4e4;
    }
    .container {
        max-width: 1170px !important;
        margin: 0 auto;
    }

}